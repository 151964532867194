import React, { Children } from "react"
import classNames from "classnames"

export const Buttons = ({ children }) => (
  <div className="buttons">{children}</div>
)

export const Button = ({
  children,
  isLink,
  isWarning,
  black,
  success,
  primary,
  bottom,
  white,
  whiteTheme,
  blackTheme,
  normal,
  medium,
}) => {
  return (
    <button
      className={classNames(
        "button is-uppercase has-family-pt-sans hvr-bounce-to-top has-text-weight-bold",
        {
          "is-link": isLink,
          "is-primary": primary,
          "is-warning": isWarning,
          "is-black": black,
          "is-success": success,
          "is-white": white,
          "mb-2": bottom,
          "is-outlined has-text-black": whiteTheme,
          "is-outlined has-text-white": blackTheme,
          "is-small": normal,
          "is-medium": medium,
        }
      )}
    >
      {children}
    </button>
  )
}
